/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.animated {
  animation: opacityAnimation 0.2s ease-in-out;
}

@keyframes opacityAnimation {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}
