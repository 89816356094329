/* custom.css */
.custom-select {
  @apply bg-transparent w-full rounded-[8px] border border-[rgba(255,255,255,0.2)] text-white placeholder:text-[rgba(222,241,255,0.5)] sm:text-sm sm:leading-6 indent-2;
}

.custom-select-dropdown {
  @apply bg-transparent w-full rounded-[8px] border border-[rgba(255,255,255,0.2)] text-white placeholder:text-[rgba(222,241,255,0.5)] sm:text-sm sm:leading-6 indent-2;
}

/* 覆盖 Ant Design 的样式 */
.ant-select-selector {
  @apply bg-transparent border-none;
}

.ant-select-selection-placeholder {
  @apply text-[rgba(222,241,255,0.5)];
}

.ant-select-selection-item {
  @apply text-white;
}

.ant-select-dropdown {
  @apply bg-transparent;
}

.ant-select-item-option-selected {
  @apply bg-[rgba(255,255,255,0.2)];
}

.ant-select-item-option-active {
  @apply bg-[rgba(255,255,255,0.1)];
}
